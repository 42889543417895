<template>
  <div>
    <b-modal v-if="additional_form"
             id="additional-form-modal"
             centered
             hide-header
             modal-class="modal-style-one"
             ok-only
             ok-title="Close"
             size="lg">
      <b-row v-if="result !== false"
             class="pt-20">
        <b-col v-for="(q,index) in additional_form.questions"
               :key="index"
               cols="12">
          <b-row class="mt-2 mb-2">
            <b-col class="d-flex align-items-center"
                   lg="8">
              <h6 class="mb-0 mb-4 mt-4">{{ q.qtext }}</h6>
            </b-col>

            <b-col lg="4">

              <div style="width: 200px">
                <b-form-group v-slot="{ ariaDescribedby }"
                              class="radio-options-buttons mb-0">
                  <b-form-radio v-model="q.value"
                                :aria-describedby="ariaDescribedby"
                                :name="'some-radios_'+index"
                                class="radio"
                                value="NO"
                                @change="calcResult">
                    <span>NO</span>
                  </b-form-radio>

                  <b-form-radio v-model="q.value"
                                :aria-describedby="ariaDescribedby"
                                :name="'some-radios_'+index"
                                class="radio"
                                value="YES"
                                @change="calcResult">
                    <span>YES</span>
                  </b-form-radio>
                </b-form-group>
              </div>


            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="result === false">
        <b-col>
          <b-alert class="mt-30"
                   show
                   variant="alert alert-card alert-danger">
            <div class="mt-12 mb-12">
              <div class="consideration-item d-inline-flex">
                <i class="item-warning i-Danger text-25 cursor-pointer text-danger pr-12" />
                <div>
                  <div>{{ additional_form.message_unsuccess }}</div>
                </div>
              </div>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

export default {
  props: ['additional_form'],
  data() {
    return {
      data: null,
      client_id: null,
      result: null,
    };
  },
  methods: {
    calcResult() {
      console.log('calcResult');
      let yesCount = 0,
          noCount  = 0;
      this.additional_form.questions.forEach(q => {
        if (q.value === 'YES') {
          yesCount = yesCount + 1;
        } else if (q.value === 'NO') {
          noCount = noCount + 1;
        }
      })

      if (yesCount + noCount === this.additional_form.questions.length) {
        if (noCount > 0) this.result = false;
        if (noCount === 0) {
          this.$bvModal.hide('additional-form-modal');
          this.$emit('submit');
        }
      }
    },
  },
};

</script>
