import moment from "moment";

import {http} from "@/services";
import {clone} from "@/mixins/clone"
import {linkHelpers} from "./linkHelpers";
import {options} from "./options";
import {toast} from '@/mixins/toast'

export const helpers = {
  mixins: [linkHelpers, options, clone, toast],
  watch: {
    $route() {
      this.initEvent();
    },
    showClientForm: {
      immediate: true,
      deep: false,
      handler(value) {
        if (value) this.initialSteps = 3
        else this.initialSteps = 2
      }
    }
  },
  data() {
    return {
      loading: false,
      startDate: moment(new Date(new Date().getFullYear(),
        new Date().getMonth(),
        1)),
      selected: null,
      eventNotFound: false,
      allowReschedule: true,
      rescheduleMessage: "",
      alreadyRescheduled: false,
      inProgress: true,
      initEventInProgress: false,
      changeEvent: true,
      monthIdx: 0,
      storeStarDates: [],
      booking: [],
      errors: [],
      emptyForm: null,
      show: {
        error: false,
        errorMessage: null
      },
      form: {
        eventKey: "",
        hostName: null,
        startDate: null,
        startTime: null,
        endTime: null,
        singleCouple: null,
        submited: false,
        lead_source: null,
        owner: null,
        duration: null,
        client1: {
          title: null,
          firstName: "",
          middleName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          sex: "",
        },
        client2: {
          title: null,
          firstName: "",
          middleName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          sex: "",
        },
      },
      hostDetails: null
    }
  },
  async created() {
    this.emptyForm = JSON.parse(JSON.stringify(this.form));
    await this.initEvent();
    this.getSlots();
  },
  computed: {
    showClientForm() {
      return ([
        'bookingHostNewClient',
        'bookingRoleNewClient',
        'bookingSourceNewClient',
        'bookingHostNonClient',
        'bookingHostNewClientLegacy'].includes(this.$route.name) && !this.$route.params.eventKey)
    },
    step() {
      return (this.$route.params.eventKey && this.initialSteps > 2 ? (this.initialSteps - 1) : this.initialSteps);
    },
    subject() {
      if (this.form.type) {
        return this.form.type.split(" ").join("+");
      }
      return "";
    },

    optionsType() {
      return [
        {
          text: "Select",
          value: null,
        },
        ...this.types.map((x) => x.text),
      ];
    },
    redirectOnSuccessUrl() {
      // if booking_redirect url then redirect to page on success
      let redirect = this.$route.query.redirect
      if (redirect) {
        if (redirect === 'mortgage') {
          let baseUrl =
            process.env.NODE_ENV === "production"
              ? "https://dm.mortgage"
              : "http://localhost:9000";

          return `${baseUrl}/booking_success`;
        }
      }
      return null
    }
  },
  methods: {
    async getSlots() {
      // get available appointment slots

      // init variables
      let host = null
      this.inProgress = true;

      // guards
      // get optional host details (required for host id)
      if (!this.hostDetails && this.$route.params.host) await this.getHostDetails()
      host = this.hostDetails ? this.hostDetails.id : null
      if (!this.startDate) return this.inProgress = false;

      // start
      let params = {
        host: host,
        role: this.role,
        appointment_type: this.form.type,
        start_date: this.startDate.format("YYYY-MM-D"),
        duration: this.form.duration
      }
      this.get(params)
    },
    setEventDetails(details) {
      // set event details from server

      this.form.transactionType = details.transaction
      this.form.description = details.description
      this.form.short = details.short
      this.form.additional_form = details.additional_form

      if (!this.form.location) {
        // only set location from server data if location is not overridden from url param
        this.form.location = details.location ? details.location : "Telephone"
      }
      if (!this.form.duration) {
        // only set duration from server data if duration is not overridden from url param
        this.form.duration = details.duration;
      }

      // lead-source override from params
      if (this.$route.query.source) {
        this.form.lead_source = this.$route.query.source
      }

    },
    setRole(role) {
      this.role = role;
      this.getSlots();
    },
    async initEvent() {
      this.initEventInProgress = true;
      if (this.$route.params.eventKey) {
        http
          .get(`scheduledevent/?eventKey=` + this.$route.params.eventKey)
          .then((response) => {

            if (response.data.length) {
              this.form.eventKey = response.data[0].eventKey;

              if (response.data[0].rescheduled) {
                this.alreadyRescheduled = true;
              } else {
                //if 1 day remaining do not allow to reschedule
                var eventDate = moment(response.data[0].startDate);
                var datediff = moment(eventDate).startOf('day').diff(moment(Date.now()).startOf('day'), 'days');

                if (datediff === 1) {
                  this.allowReschedule = false;
                  this.rescheduleMessage = "is tomorrow";
                }
                else if (datediff === 0) {
                  this.allowReschedule = false;
                  this.rescheduleMessage = "is today";
                }
              }

            } else {
              this.eventNotFound = true;
            }
            this.initEventInProgress = false;
          })
          .catch((e) => {
            this.errors.push(e);
            this.initEventInProgress = false;
          });
      }
      if (this.$route.params.host) {
        this.appointmentsRequested.host = this.$route.params.host
          .split('-')
          .map((x) => x[0].toUpperCase() + x.substring(1))
          .join(' ');
      }
      if (this.$route.params.role) {
        this.role = this.$route.params.role.split("-").map((x) => x[0].toUpperCase() + x.substring(1)).join(" ");
      }
      if (this.$route.params.ids) {
        this.form.ids = this.$route.params.ids;
      }
      if (this.$route.params.names) {
        this.form.names = this.$route.params.names
          .split("-")
          .map((x) => x[0].toUpperCase() + x.substring(1))
          .join(" ");
      }
      if (this.$route.params.type && this.$route.params.type !== "slc") {
        this.form.type = this.$route.params.type
          .split("-")
          .map((x) => x[0].toUpperCase() + x.substring(1))
          .join(" ").split("_").join("-");
      }
      if (this.$route.params.leadSource) {
        this.form.lead_source = this.$route.params.leadSource;
      }
      if (this.$route.query.duration) {
        // duration override
        this.form.duration = this.$route.query.duration
      }
      if (this.$route.query.location && this.locationOptions.includes(this.$route.query.location)) {
        // location override from params
        this.form.location = this.$route.query.location
      }
      if (this.$route.params.title) {
        // override title from params
        this.form.title = this.$route.params.title.split("-").join(" ");
      }
      if (['bookingHostNonClient', 'bookingRoleNonClient'].includes(this.$route.name)) {
        // set meeting type as Meeting if it is a non-client meeting
        this.form.type = 'Meeting';
        this.form.non_client = true;
      }
      return true
    },
    prevMonth() {
      this.monthIdx = this.monthIdx - 1;
      this.startDate = this.storeStarDates[this.monthIdx];
      this.getSlots();
    },
    nextMonth() {
      this.storeStarDates[this.monthIdx] = this.startDate;
      this.monthIdx = this.monthIdx + 1;
      this.startDate = moment(this.startDate).add(1, 'M').startOf("month");
      this.getSlots();
    },
    currentMonth() {
      this.monthIdx = 0;
      this.startDate = this.storeStarDates[0];
      this.getSlots();
    },
    slots(day) {
      if (day) {
        return day.timeslots.map(function (e) {
          return e.time;
        });
      }
    },
    onReset(event) {
      event.preventDefault();
      // Reset form values
      this.form = JSON.parse(JSON.stringify(this.emptyForm));
    },
    setSelected({day, time, index, selectedEmployeeIndex = null}) {
      this.form.startDate = moment(day.date).format("YYYY-MM-DD");
      this.form.startTime = time;
      this.form.endTime = moment(this.form.startTime,
        'HH:mm').add(this.form.duration,
        'minutes').format('HH:mm');

      if (selectedEmployeeIndex === null) {
        // randomise the host in the employees available (if anyone selected)
        selectedEmployeeIndex = Math.floor(Math.random() * day.timeslots[index].employees.length)
      }

      let selectedOwner = day.timeslots[index].employees[selectedEmployeeIndex]

      this.form.owner = selectedOwner.id;

      if (!this.form.title) {
        // set owner initials
        this.form.title = selectedOwner.name.split(' ').map(word => word[0]).join('');
        // append event description
        this.form.title += ` - ${this.form.short ? (this.form.short + ' - ') : ''}${this.form.location}`;
      }

      this.form.submited = false;

      if (this.form.eventKey) {
        let data = this.clone(this.form)
        delete data.client1;
        delete data.singleCouple;
        if (data.client2) {
          delete data.client2;
        }
        this.reschedule(data)
      } else if (this.initialSteps === 2) {
        let data = this.clone(this.form)
        if (this.form.ids) {
          delete data.client1;
          delete data.singleCouple;
          if (data.client2) {
            delete data.client2;
          }
        }
        this.createNew(data)
      }
    },
    createNew(data) {
      this.loading = true
      http
        .post('save_booking/', data)
        .then((response) => {
          this.loading = false
          this.form.submited = true;
          this.form.eventKey = response.data.eventKey;
          this.form.hostName = response.data.hostName;
          if (this.redirectOnSuccessUrl) window.open(this.redirectOnSuccessUrl)
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false
          this.handleErrors(e.response.data)
        })
    },
    reschedule(data) {
      this.loading = true
      http.post(`rescheduledevent/`, data).then((response) => {
        this.loading = false
        this.form.submited = true;
        this.form.hostName = response.data.hostName;
        let params = {eventKey: response.data.eventKey};
        if (this.form.lead_source) {
          params.leadSource = this.form.lead_source;
        }


        this.$router.push({
          name: this.$route.name,
          params: params,
        }).catch((e) => console.log(e));

      }).catch((e) => {
        this.loading = false
        this.errors.push(e);
        this.handleErrors(e.response.data)
      });
    },
    handleErrors(error) {
      this.form.submited = false;
      this.form.startDate = null
      this.form.startTime = null
      this.form.endTime = null
      this.$refs.calendarMonth.clear()


      if (typeof error === 'object' && 'action' in error) {
        if (error.action === 'BLOCKED') {
          this.show.error = true
          this.show.errorMessage = error.message
        } else if (error.action === 'BOOKED') {
          // if double booked then refresh event slots
          this.toastError(error.message)
          this.getSlots()
        }
      }
    },
    navToEvent() {
      let params = {eventKey: this.form.eventKey};
      if (this.form.type) {
        params.type = this.form.type.split(' ').join('-');
      }
      if (this.form.ids) {
        params.ids = this.form.ids;
      }
      if (this.form.names) {
        params.names = this.form.names.split(' ').join('-');
      }
      if (this.role) {
        params.role = this.role.split(' ').join('-');
      }
      if (this.form.lead_source) {
        params.leadSource = this.form.lead_source;
      }
      if (this.appointmentsRequested.host) {
        params.host = this.appointmentsRequested.host.replaceAll(' ', '-')
      }
      this.$router.push({
        name: this.appointmentsRequested.host ? 'bookingHost' : 'bookingRole',
        params: params,
      }).catch((e) => console.log(e));
    },
    get(params) {
      http.get('booking/', {params: params}).then((response) => {
        this.inProgress = false;

        let data = []
        // set event details for frontend display to user
        this.setEventDetails(response.data.event_details)
        // convert response for calender options
        let dateKeys = Object.keys(response.data.slots)

        // remove un-selected days if preferred days set in the link
        if(this.$route.query.days){
          const available_days_of_week = this.$route.query.days.split(",").map(s=>(s*1));
          let available_days_for_booking = []
          dateKeys.forEach(d=>{
            if(available_days_of_week.includes(moment(d, "YYYY-MM-DD").day())){
              available_days_for_booking.push(d);
            }
          })
          
          dateKeys = available_days_for_booking;
        }

        // create day objects with timeslots and day attribute
        for (let key in dateKeys) {
          let timeSlots = []
          let timeKeys = Object.keys(response.data.slots[dateKeys[key]])
          // create time slot objects with time and employees
          timeKeys = timeKeys.sort((a, b) => Number(a.replace(':', '')) - Number(b.replace(':', '')))
          for (let t in timeKeys) {
            let employees = []
            let employeeKeys = Object.keys(response.data.slots[dateKeys[key]][timeKeys[t]])
            // create employee object with id and name
            for (let e in employeeKeys) {
              employees.push({
                id: employeeKeys[e],
                name: response.data.slots[dateKeys[key]][timeKeys[t]][employeeKeys[e]]
              })
            }
            timeSlots.push({
              time: timeKeys[t],
              employees: employees
            })
          }
          data.push({
            date: `${dateKeys[key]}T00:00:00`,
            timeslots: timeSlots
          })
        }
        this.booking = data

      }).catch((e) => {
        this.errors.push(e);
        this.inProgress = false;
      });
    },
    async getHostDetails() {
      return http.get('booking_host_detail', {params: {name: this.appointmentsRequested.host.replaceAll('-', ' ')}}).then(
        response => {
          this.hostDetails = response.data
          return true
        }
      ).catch(
        error => {
          console.log(error)
          return false
        }
      )
    }
  }
};
