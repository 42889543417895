<template>
  <div class="accordion">
    <div>
      <b-card no-body>
        <b-card-header
          class="d-flex justify-content-between"
          header-tag="header"
        >
          <div class="card-title mb-0">
            <div class="oneTwoThree">2</div>
            Your Details
          </div>
          <div class="card-title mb-0"
               v-if="
              form.client1.firstName && form.client1.lastName && form.submited
            "
          >
            {{ form.client1.firstName + " " + form.client1.lastName }}
            <span v-if="form.client2.firstName && form.client2.lastName">{{
                "& " + form.client2.firstName + " " + form.client2.lastName
              }}</span>
          </div>
        </b-card-header>
        <b-collapse
          :id="'accordion-form'"
          :visible="form.startDate !== null && !form.submited"
        >
          <b-card-body class="p-5">
            <p style="font-size: 13px" class="w-100 text-center pb-2"
               v-if="form.type === 'Mortgage Consultation' || form.type === 'Re-Mortgage Consultation'">Will the
              mortgage be in a single name or joint names as a couple?</p>
            <p style="font-size: 13px" class="w-100 text-center pb-2"
               v-if="form.type === 'Estate Planning Consultation' || form.type === 'Estate Planning Consultation (& Free Will)' || form.type === 'Estate Planning Consultation Referral Discounted Services'">
              Are you single or do you have a partner or spouse?</p>
            <b-row>
              <div class="w-100">
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  class="radio-options-buttons wide mb-0"
                >
                  <b-form-radio
                    v-for="(option, index) in optionsSingleCouple"
                    :key="index"
                    v-model="form.singleCouple"
                    :aria-describedby="ariaDescribedby"
                    :state="serverErrors.singleCouple ? false : null"
                    :value="option.value"
                  ><span>{{ option.text }}</span>
                  </b-form-radio>
                </b-form-group>
                <b-form-invalid-feedback
                  id="type-feedback"
                  :state="serverErrors.serverErrors ? false : null"
                >
                  {{ serverErrors.serverErrors }}
                </b-form-invalid-feedback>
              </div>

              <hr
                v-if="form.singleCouple !== null"
                class="mb-3"
                style="margin-top: 30px"
              />

              <b-col v-if="form.singleCouple !== null" class="" md="12">
                <b-row>
                  <b-col md="3" class="mt-2 mb-2">
                    <label>Title</label>
                    <b-select
                      id="client1_title"
                      v-model="form.client1.title"
                      :options="titles"
                      :state="serverErrors.client1.title ? false : null"
                      size="md"
                    ></b-select>
                    <b-form-invalid-feedback
                      id="title1"
                      :state="serverErrors.client1.title ? false : null"
                    >
                      {{ serverErrors.client1.title }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col md="4" class="mt-2 mb-2">
                    <label>First Name</label>

                    <b-input
                      id="client1_firstName"
                      v-model="form.client1.firstName"
                      :state="serverErrors.client1.firstName ? false : null"
                      placeholder="e.g. Jillian"
                      size="md"
                    />
                    <b-form-invalid-feedback
                      id="firstName1"
                      :state="serverErrors.client1.firstName ? false : null"
                    >
                      {{ serverErrors.client1.firstName }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col md="5" class="mt-2 mb-2">
                    <label>Last Name</label>

                    <b-input
                      id="client1_firstName"
                      v-model="form.client1.lastName"
                      :state="serverErrors.client1.lastName ? false : null"
                      placeholder="e.g. Bloggs"
                      size="md"
                    />
                    <b-form-invalid-feedback
                      id="lastName1"
                      :state="serverErrors.client1.lastName ? false : null"
                    >
                      {{ serverErrors.client1.lastName }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-2 mb-2">
                    <label>Email</label>
                    <b-input
                      id="client1_email"
                      v-model="form.client1.email"
                      :state="serverErrors.client1.email ? false : null"
                      placeholder="e.g. mail@web.co.uk"
                      size="md"
                      type="email"
                    ></b-input>
                    <b-form-invalid-feedback
                      id="email1"
                      :state="serverErrors.client1.email ? false : null"
                    >
                      {{ serverErrors.client1.email }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col md="5" class="mt-2 mb-2">
                    <label>Mobile</label>
                    <b-input
                      id="client1_phoneNumber"
                      v-model="form.client1.phoneNumber"
                      :state="serverErrors.client1.phoneNumber ? false : null"
                      placeholder="e.g. 07500112233"
                      size="md"
                    ></b-input>
                    <b-form-invalid-feedback
                      id="phoneNumber1"
                      :state="serverErrors.client1.phoneNumber ? false : null"
                    >
                      {{ serverErrors.client1.phoneNumber }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-col>

              <hr v-if="form.singleCouple" class="mb-0 mt-4"/>

              <transition>
                <b-col
                  v-if="form.singleCouple"
                  class="client2-container mt-4"
                  md="12"
                >
                  <b-row>
                    <b-col md="3" class="mt-2 mb-2">
                      <label>Title</label>
                      <b-select
                        id="client2_title"
                        v-model="form.client2.title"
                        :options="titles"
                        :state="serverErrors.client2.title ? false : null"
                        size="md"
                      ></b-select>
                      <b-form-invalid-feedback
                        id="title2"
                        :state="serverErrors.client2.title ? false : null"
                      >
                        {{ serverErrors.client2.title }}
                      </b-form-invalid-feedback>
                    </b-col>
                    <b-col md="4" class="mt-2 mb-2">
                      <label>First Name</label>

                      <b-input
                        id="client2_firstName"
                        v-model="form.client2.firstName"
                        :state="serverErrors.client2.firstName ? false : null"
                        placeholder="e.g. Jillian"
                        size="md"
                      />
                      <b-form-invalid-feedback
                        id="firstName2"
                        :state="serverErrors.client2.firstName ? false : null"
                      >
                        {{ serverErrors.client2.firstName }}
                      </b-form-invalid-feedback>
                    </b-col>
                    <b-col md="5" class="mt-2 mb-2">
                      <label>Last Name</label>

                      <b-input
                        id="client2_firstName"
                        v-model="form.client2.lastName"
                        :state="serverErrors.client2.lastName ? false : null"
                        placeholder="e.g. Bloggs"
                        size="md"
                      />
                      <b-form-invalid-feedback
                        id="lastName2"
                        :state="serverErrors.client2.lastName ? false : null"
                      >
                        {{ serverErrors.client2.lastName }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col class="mt-2 mb-2">
                      <label>Email</label>
                      <b-input
                        id="client2_email"
                        v-model="form.client2.email"
                        :state="serverErrors.client2.email ? false : null"
                        placeholder="e.g. mail@web.co.uk"
                        size="md"
                        type="email"
                      ></b-input>
                      <b-form-invalid-feedback
                        id="email2"
                        :state="serverErrors.client2.email ? false : null"
                      >
                        {{ serverErrors.client2.email }}
                      </b-form-invalid-feedback>
                    </b-col>
                    <b-col md="5" class="mt-2 mb-2">
                      <label>Mobile</label>
                      <b-input
                        id="client2_phoneNumber"
                        v-model="form.client2.phoneNumber"
                        :state="serverErrors.client2.phoneNumber ? false : null"
                        placeholder="e.g. 07500112233"
                        size="md"
                      ></b-input>
                      <b-form-invalid-feedback
                        id="phoneNumber2"
                        :state="serverErrors.client2.phoneNumber ? false : null"
                      >
                        {{ serverErrors.client2.phoneNumber }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                </b-col>
              </transition>
            </b-row>

            <b-row v-if="form.singleCouple !== null">
              <b-col>
                <label>Notes / Useful Information</label>
                <b-textarea
                  v-model="form.notes"
                  placeholder="e.g. interested in making a will"
                  style="min-height: 108px"
                  debounce="500"
                />
              </b-col>
            </b-row>

            <b-button
              v-if="form.startDate !== null && form.singleCouple !== null"
              class="w-100 mt-5 pt-3 pb-3"
              size="md"
              variant="secondary"
              :disabled="loading"
              @click="preSubmit"
            >Confirm Appointment
              <b-spinner v-if="loading" class="small-spinner align-middle ml-1"/>
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <ModalAdditionalForm v-if="form.additional_form" :additional_form="form.additional_form" @submit="onSubmit"/>
  </div>
</template>

<script>
import {clone} from "@/mixins/clone"

import ModalAdditionalForm from "@/forms/Components/ModalAdditionalForm";

export default {
  mixins: [clone],
  name: "ClientForm",
  components: {ModalAdditionalForm},
  props: ["form", 'loading'],
  data() {
    return {
      optionsSingleCouple: [
        {
          text: "Single",
          value: false,
        },
        {
          text: "Couple",
          value: true,
        },
      ],
      titles: [
        {
          text: "Select",
          value: null,
        },
        "Mr",
        "Mrs",
        "Miss",
        "Master",
        "Ms",
        'Dr'
      ],
      errors: [],
      serverErrors: {
        client1: {
          title: null,
          firstName: null,
          middleName: null,
          lastName: null,
          phoneNumber: null,
          email: null,
          sex: null,
        },
        client2: {
          title: null,
          firstName: null,
          lastName: null,
          phoneNumber: null,
          email: null,
          sex: null,
        },
      },
    };
  },
  methods: {
    clearErrors() {
      this.serverErrors.client1.title = null;
      this.serverErrors.client1.firstName = null;
      this.serverErrors.client1.middleName = null;
      this.serverErrors.client1.lastName = null;
      this.serverErrors.client1.phoneNumber = null;
      this.serverErrors.client1.email = null;
      this.serverErrors.client1.sex = null;
      this.serverErrors.client2.title = null;
      this.serverErrors.client2.firstName = null;
      this.serverErrors.client2.lastName = null;
      this.serverErrors.client2.phoneNumber = null;
      this.serverErrors.client2.email = null;
      this.serverErrors.client2.sex = null;
    },
    checkForm() {
      this.clearErrors();

      let count = 0;
      if (!this.form.singleCouple) {
        if (!this.form.client1.firstName.trim()) {
          this.serverErrors.client1.firstName = "First name is required";
          count += 1;
        }
        if (!this.form.client1.lastName.trim()) {
          this.serverErrors.client1.lastName = "Last name is required";
          count += 1;
        }
        if (!this.form.client1.phoneNumber.trim()) {
          this.serverErrors.client1.phoneNumber = "Phone number is required";
          count += 1;
        }
        if (!this.form.client1.email.trim()) {
          this.serverErrors.client1.email = "Email address is required";
          count += 1;
        }
        if (!this.form.client1.title) {
          this.serverErrors.client1.title = "Title is required";
          count += 1;
        }
      } else {
        if (!this.form.client1.firstName.trim()) {
          this.serverErrors.client1.firstName = "First name is required";
          count += 1;
        }
        if (!this.form.client1.lastName.trim()) {
          this.serverErrors.client1.lastName = "Last name is required";
          count += 1;
        }
        if (!this.form.client1.title) {
          this.serverErrors.client1.title = "Title is required";
          count += 1;
        }
        if (!this.form.client2.firstName.trim()) {
          this.serverErrors.client2.firstName = "First name is required";
          count += 1;
        }
        if (!this.form.client2.lastName.trim()) {
          this.serverErrors.client2.lastName = "Last name is required";
          count += 1;
        }
        if (!this.form.client2.title) {
          this.serverErrors.client2.title = "Title is required";
          count += 1;
        }


        if (!this.form.client1.phoneNumber.trim() && !this.form.client1.email.trim()) {
          this.serverErrors.client1.email =
            "Email or phone number is required";
          this.serverErrors.client1.phoneNumber =
            "Email or phone number is required";
          count += 1;
        }
        if (!this.form.client2.phoneNumber.trim() && !this.form.client2.email.trim()) {
          this.serverErrors.client2.email =
            "Email or phone number is required";
          this.serverErrors.client2.phoneNumber =
            "Email or phone number is required";
          count += 1;
        }

        if (this.form.client1.email.trim() && this.form.client2.email.trim() && this.form.client1.email === this.form.client2.email) {
          this.serverErrors.client1.email =
            "Email address cannot be the same for both";
          this.serverErrors.client2.email =
            "Email address cannot be the same for both";
          count += 1;
        }
      }
      return count;
    },
    preSubmit(event) {

      if (!this.checkForm()) {
        event.preventDefault();
        if (!this.form.additional_form) {
          this.onSubmit()
        } else {
          this.$bvModal.show('additional-form-modal');
        }
      }
    },
    onSubmit() {
      if (!this.checkForm()) {
        if (this.form.client1.title) {
          if (
            this.form.client1.title === "Mr" ||
            this.form.client1.title === "Master"
          ) {
            this.form.client1.sex = "male";
          } else {
            this.form.client1.sex = "female";
          }
        }

        if (this.form.client2.title) {
          if (
            this.form.client2.title === "Mr" ||
            this.form.client2.title === "Master"
          ) {
            this.form.client2.sex = "male";
          } else {
            this.form.client2.sex = "female";
          }
        }
        let data = this.clone(this.form)
        if (!data.client2.title) {
          delete data.client2;
        }
        this.$emit('createNew', data)
      }
    },
  },
};
</script>
