<template>
  <div class="timeslots">
    <div v-if="selectedBooking && selectedBooking.timeslots.length"
         class="d-flex flex-row pt-2">
      <div class="timeSelectorWrapper mr-5 w-50 ml-2">
        <div v-for="(option, index) in selectedBooking.timeslots"
             :key="index"
             class="d-flex flex-row">
          <div :id="`popover` + index"
               :class="{'selected-timeslot' : timeslotSelected === option.time }"
               class="timeSelector"
               @click="timeslotSelected = option.time">{{ addAMPM(option.time) }}
          </div>
          <b-popover 
                     :target="`popover` + index"
                     delay="500"
                     placement="top"
            triggers="hover focus">
            <span v-for="(p, person_index) in option.employees" :key="person_index" >
                     <b-badge href="#" class="mr-1"
                     variant="success"
                     @click="$emit('setSelected',{ x: selectedBooking, y: option.time, z:index, q:person_index})">
                      {{p.name}} <b-icon icon="check"></b-icon>
                     </b-badge>
            </span>
          </b-popover>
          <div :class="{'show-confirm' : option.time === timeslotSelected}"
               class="timeConfirm"
               @click="$emit('setSelected',{ x: selectedBooking, y: option.time, z:index})">Confirm
          </div>
        </div>
      </div>
      <div class="w-50">
        <h5 class="d-block w-100 pl-3 pb-2 pt-3">{{ dateSelected|moment("dddd, MMMM Do") }}</h5>
        <div class="p-3">
          <div class="mb-2">Type:
            <span style="font-weight: 500">{{ kind }}</span>
          </div>
          <div class="mb-2">Duration:
            <span style="font-weight: 500">{{ parseInt(durationInMinutes) - 10 }} - {{ parseInt(durationInMinutes) + 10 }} Minutes</span>
          </div>
          <div v-if="timeslotSelected"
               class="mb-2">Start Time:
            <span style="font-weight: 500">{{ addAMPM(timeslotSelected) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else
         class="d-flex flex-column align-items-center justify-content-center">
      <h5 class="d-block text-center pb-3 pt-1">{{ dateSelected|moment("dddd, MMMM Do") }}</h5>
      <div class="timeSelector w-75 pt-3 pb-3">
        No Appointments
      </div>
    </div>
  </div>
</template>

<script>
import { BBadge, BIcon } from 'bootstrap-vue'
export default {
  name: "CalendarTimeSelector",

  components: {
    BBadge,
    BIcon,
  },
  props: {
    dateSelected: {
      type: String,
    },
    kind: {
      type: String,
    },
    durationInMinutes: {
      type: Number,
    },
    selectedBooking: {
      type: Object,
    },
    splitDays: {
      type: Array,
    }
  },

  data() {
    return {
      timeslotSelected: null,
    };
  },
  methods: {
    addAMPM(value) {
      let timeString = value;
      let H = +timeString.substr(0,
        2);
      let h = H % 12 || 12;
      let ampm = (H < 12 || H === 24) ? " AM" : " PM";
      return timeString = h + timeString.substr(2,
        3) + ampm;
    },
    listEmployees(option) {
      let employeeList = '';
      for (var i = 0; i < option.employees.length; i++) {
        employeeList += option.employees[i].name + ', '
      }
      return employeeList
    },
    test(obj){
      console.log('obj', obj);
    },
  }
};
</script>


