<template>
  <b-container>
    <b-row>
      <b-col lg="8"
             offset-lg="2">
        <b-overlay
          :show="!allowReschedule"
          opacity="0"
          spinner-type=""
        >
          <b-form
            v-if="show"
            @reset="onReset">

            <meeting-type
              v-if="role"
              @setRole="setRole"
              :role="role"
              v-model="form"
            />

            <CalendarMonth
              v-if="form.type !== null && !show.error"
              :booking="booking"
              :durationInMinutes="form.duration"
              :inProgress="inProgress"
              :kind="form.type"
              :oneTwoThree="1"
              :monthIdx="monthIdx"
              @currentMonth="currentMonth"
              @nextMonth="nextMonth"
              @prevMonth="prevMonth"
              @setSelected="setSelected"
              :allowReschedule="allowReschedule"
              :rescheduleMessage="rescheduleMessage"
              :eventNotFound="eventNotFound"
              :alreadyRescheduled="alreadyRescheduled"
              ref="calendarMonth"
            />

            <ClientForm
              v-if="showClientForm"
              :form="form"
              :loading="loading"
              @createNew="createNew"
            />

            <MeetingConfirmationPanel
              v-if="!show.error"
              v-model="form"
              @createIcs="createIcs"
              @navToEvent="navToEvent"
              :step="step"
            />

            <b-alert show variant="danger" v-if="show.error">
              Error Occurred: {{ show.errorMessage }}
            </b-alert>

          </b-form>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {helpers} from '@/forms/Mixins/helpers';

import MeetingConfirmationPanel from "@/forms/Components/MeetingConfirmationPanel";
import CalendarMonth from "@/forms/Calender/CalendarMonth";
import ClientForm from "./Components/ClientForm";
import MeetingType from "@/forms/Components/MeetingType";

export default {
  name: 'BookingForm',
  mixins: [helpers],
  components: {
    MeetingType,
    MeetingConfirmationPanel,
    CalendarMonth,
    ClientForm,
  },
  data() {
    return {
      role: null,
      initialSteps: 2,
      form: {
        location: "Telephone", // there should be a default
        type: null,
        transactionType: null,
        description: null,
        short: null,
        duration: null,
      },
      appointmentsRequested: {
        startTime: null,
        host: ''
      }
    };
  },
};
</script>
