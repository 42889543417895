let deepClone = require('lodash/cloneDeep');

export const clone = {
  name: 'cloneHelpers',
  methods: {
    clone(original) {
      return deepClone(original);
    }
  }
};
