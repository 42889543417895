<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created() {
    document.title = "DM Book Appointment";
  },
};
</script>

<style>
</style>