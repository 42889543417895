<template>
  <div class="day-of-week">
    <div v-for="weekday in weekdays" :key="weekday">{{ weekday }}</div>
  </div>
</template>

<script>
const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default {
  name: "CalendarWeekdays",

  computed: {
    weekdays() {
      return WEEKDAYS;
    }
  }
};
</script>

<style scoped>
.day-of-week {
  display: flex;
  flex-direction: row;
  background-color: var(--primary);
  color: white;
}
.day-of-week > * {
  flex-basis: 14.285%;
  flex-grow: 1;
  text-align: center;
}
</style>
