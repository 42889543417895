<template>
  <div class="accordion">
    <div>
      <b-card no-body>
        <b-card-header class="d-flex justify-content-between"
                       header-tag="header"
                       style="cursor: pointer"
                       @click="clear">
          <div class="card-title mb-0">
            <div class="oneTwoThree">{{oneTwoThree}}</div>
            {{ startDate ? "Change Date" : "Date / Time" }}
          </div>
          <div class="card-title mb-0" v-if="startTime">
            {{ startDate|moment("dddd, MMM Do") }} {{ startTime }}
          </div>
          <CalendarDateSelector v-if="!startTime"
                                :current-date="today"
                                :inProgress="inProgress"
                                :selected-date="selectedDate"
                                :monthIdx="monthIdx"
                                @currentMonth="$emit('currentMonth')"
                                @dateSelected="selectDate"
                                @nextMonth="$emit('nextMonth')"
                                @prevMonth="$emit('prevMonth')" ref="dateSelector" />
        </b-card-header>
        <b-collapse :id="'accordion-apt'"
                    :visible="startDate===null">
          <b-card-body class="p-0">
            <div class="calendar-month">

              <CalendarWeekdays />
              <div v-if="allowReschedule===false"
                   class="noAptsWrapper h-100 align-items-center d-flex justify-content-center">
                <div class="noAptsInner w-75 d-flex justify-content-between align-items-center"><div>As the appointment {{ rescheduleMessage }} it cannot be rescheduled online please call 01785 336222.</div>
                </div>
              </div>

              <div v-if="eventNotFound"
                   class="noAptsWrapper h-100 align-items-center d-flex justify-content-center">
                <div class="noAptsInner w-75 d-flex justify-content-between align-items-center"><div>Appointment not found. Please check the link and try again.</div>
                </div>
              </div>

              <div v-if="alreadyRescheduled"
                   class="noAptsWrapper h-100 align-items-center d-flex justify-content-center">
                <div class="noAptsInner w-75 d-flex justify-content-between align-items-center"><div>This link has expired. Please contact our office on 01785 336222.</div>
                </div>
              </div>

              <div v-else-if="inProgress||initEventInProgress"
                   class="progressWrapper h-100 align-items-center d-flex justify-content-center">
                <div class="progressInner w-75 d-flex justify-content-between align-items-center"><div>Checking availability...</div><b-spinner/></div>
              </div>

              <div v-else-if="inProgress===false&&noAppointmentsCurrentMonth===0"
                   class="noAptsWrapper h-100 align-items-center d-flex justify-content-center">
                <div class="noAptsInner w-75 d-flex justify-content-between align-items-center"><div>No availability please check next month</div><b-button class="d-inline-block ml-2" @click="selectNextMonth">Next Month</b-button>
                </div>
              </div>

              <div class="days-grid">
                <CalendarMonthDayItem v-for="day in splitDays[0]"
                                      :key="day.date"
                                      :count="slotCount(day)"
                                      :day="day"
                                      :is-selected="day.date === dateSelected"
                                      :is-today="day.date === today"
                                      @dateSelected="dateSelected = day.date" />
              </div>

              <CalendarTimeSelector v-if="splitDays[0].find(days => days.date === dateSelected)"
                                    :dateSelected="dateSelected"
                                    :durationInMinutes="durationInMinutes"
                                    :kind="kind"
                                    :selectedBooking="selectedBooking"
                                    :splitDays="splitDays[0]"
                                    @setSelected="setSelected" />

              <div class="days-grid">
                <CalendarMonthDayItem v-for="day in splitDays[1]"
                                      :key="day.date"
                                      :count="slotCount(day)"
                                      :day="day"
                                      :in-past="day.date < Date.now()"
                                      :is-selected="day.date === dateSelected"
                                      :is-today="day.date === today"
                                      @dateSelected="dateSelected = day.date" />
              </div>

              <CalendarTimeSelector v-if="splitDays[1].find(days => days.date === dateSelected)"
                                    :dateSelected="dateSelected"
                                    :durationInMinutes="durationInMinutes"
                                    :kind="kind"
                                    :selectedBooking="selectedBooking"
                                    :splitDays="splitDays[1]"
                                    @setSelected="setSelected" />

              <div class="days-grid">
                <CalendarMonthDayItem v-for="day in splitDays[2]"
                                      :key="day.date"
                                      :count="slotCount(day)"
                                      :day="day"
                                      :in-past="day.date < Date.now()"
                                      :is-selected="day.date === dateSelected"
                                      :is-today="day.date === today"
                                      @dateSelected="dateSelected = day.date" />
              </div>

              <CalendarTimeSelector v-if="splitDays[2].find(days => days.date === dateSelected)"
                                    :dateSelected="dateSelected"
                                    :durationInMinutes="durationInMinutes"
                                    :kind="kind"
                                    :selectedBooking="selectedBooking"
                                    :splitDays="splitDays[2]"
                                    @setSelected="setSelected" />


              <div class="days-grid">
                <CalendarMonthDayItem v-for="day in splitDays[3]"
                                      :key="day.date"
                                      :count="slotCount(day)"
                                      :day="day"
                                      :in-past="day.date < Date.now()"
                                      :is-selected="day.date === dateSelected"
                                      :is-today="day.date === today"
                                      @dateSelected="dateSelected = day.date" />
              </div>

              <CalendarTimeSelector v-if="splitDays[3].find(days => days.date === dateSelected)"
                                    :dateSelected="dateSelected"
                                    :durationInMinutes="durationInMinutes"
                                    :kind="kind"
                                    :selectedBooking="selectedBooking"
                                    :splitDays="splitDays[3]"
                                    @setSelected="setSelected" />

              <div class="days-grid">
                <CalendarMonthDayItem v-for="day in splitDays[4]"
                                      :key="day.date"
                                      :count="slotCount(day)"
                                      :day="day"
                                      :in-past="day.date < Date.now()"
                                      :is-selected="day.date === dateSelected"
                                      :is-today="day.date === today"
                                      @dateSelected="dateSelected = day.date" />
              </div>

              <CalendarTimeSelector v-if="splitDays[4].find(days => days.date === dateSelected)"
                                    :dateSelected="dateSelected"
                                    :durationInMinutes="durationInMinutes"
                                    :kind="kind"
                                    :selectedBooking="selectedBooking"
                                    :splitDays="splitDays[4]"
                                    @setSelected="setSelected" />

              <div class="days-grid" v-if="splitDays[5]">
                <CalendarMonthDayItem v-for="day in splitDays[5]"
                                      :key="day.date"
                                      :count="slotCount(day)"
                                      :day="day"
                                      :in-past="day.date < Date.now()"
                                      :is-selected="day.date === dateSelected"
                                      :is-today="day.date === today"
                                      @dateSelected="dateSelected = day.date" />
              </div>

              <CalendarTimeSelector v-if="splitDays[5]&&splitDays[5].find(days => days.date === dateSelected)"
                                    :dateSelected="dateSelected"
                                    :durationInMinutes="durationInMinutes"
                                    :kind="kind"
                                    :selectedBooking="selectedBooking"
                                    :splitDays="splitDays[5]"
                                    @setSelected="setSelected" />

            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import CalendarMonthDayItem from "./CalendarMonthDayItem";
import CalendarDateSelector from "./CalendarDateSelector";
import CalendarWeekdays from "./CalendarWeekdays";
import CalendarTimeSelector from "./CalendarTimeSelector";
import moment from "moment";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  name: "CalendarMonth",
  props: [
    'booking',
    'kind',
    'inProgress',
    'initEventInProgress',
    'durationInMinutes',
    'oneTwoThree',
    'allowReschedule',
    'rescheduleMessage',
    'alreadyRescheduled',
    'eventNotFound',
    'monthIdx'
  ],
  components: {
    CalendarTimeSelector,
    CalendarMonthDayItem,
    CalendarDateSelector,
    CalendarWeekdays
  },
  data() {
    return {
      selectedDate: dayjs(),
      dateSelected: null,
      startDate: null,
      startTime: null,
    };
  },
  computed: {
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays
      ];
    },
    selectedBooking() {
      return this.booking.find(slot => slot.date.slice(0,
        -9) === this.dateSelected)
    },
    splitDays() {
      let days = this.days
      let arrays = [];
      const size = 7;

      while (this.days.length > 0) {
        arrays.push(days.splice(0, size));
      }

      return arrays
    },
    today() {
      return dayjs().
        format("YYYY-MM-DD");
    },

    month() {
      return Number(this.selectedDate.format("M"));
    },

    year() {
      return Number(this.selectedDate.format("YYYY"));
    },

    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).
        daysInMonth();
    },

    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((day,
                                                       index) => {
        return {
          date: dayjs(`${this.year}-${this.month}-${index + 1}`).
            format("YYYY-MM-DD"),
          isCurrentMonth: true
        };
      });
    },
    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(this.currentMonthDays[0].date);
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).
        subtract(1,
          "month");

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday ? firstDayOfTheMonthWeekday - 1 : 6;

      const previousMonthLastMondayDayOfMonth = dayjs(this.currentMonthDays[0].date).
        subtract(visibleNumberOfDaysFromPreviousMonth,
          "day").
        date();

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day,
                                                                   index) => {
        return {
          date: dayjs(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`).
            format("YYYY-MM-DD"),
          isCurrentMonth: false
        };
      });
    },
    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(`${this.year}-${this.month}-${this.currentMonthDays.length}`);

      const nextMonth = dayjs(`${this.year}-${this.month}-01`).
        add(1,
          "month");

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday ? 7 - lastDayOfTheMonthWeekday : lastDayOfTheMonthWeekday;

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day,
                                                               index) => {
        return {
          date: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).
            format("YYYY-MM-DD"),
          isCurrentMonth: false
        };
      });
    },
    noAppointmentsCurrentMonth() {
      let count = 0
      for (let i in this.currentMonthDays) {
        count += this.slotCount(this.currentMonthDays[i])
      }
      // console.log('count ---> ', count);
      return count
    }
  },
  methods: {
    setSelected({x, y, z, q=null}) {
      this.startDate = moment(x.date).
        format("YYYY-MM-DD");
      this.startTime = y;
      this.$emit('setSelected',
        {
          day: x,
          time: y,
          index: z,
          selectedEmployeeIndex: q
        })
    },
    slotCount(day) {
      let cnt = 0;
      if (day && this.booking && this.today!=dayjs(day.date).format("YYYY-MM-DD")) {
        let theDay = this.booking.find(e => (dayjs(e.date).
          format("YYYY-MM-DD")) === (dayjs(day.date).
          format("YYYY-MM-DD")));
        if (theDay) {
          cnt = theDay.timeslots.length;
        }
      }
      return cnt;
    },
    selectNextMonth() {
      const nextMonth = moment(this.selectedDate.$d).add(1,'M').startOf("month");
      this.selectedDate = dayjs(nextMonth._d);
      this.$emit("nextMonth");
    },
    addAMPM(value) {
      let timeString = value;
      let H = +timeString.substr(0,
        2);
      let h = H % 12 || 12;
      let ampm = (H < 12 || H === 24) ? " AM" : " PM";
      return timeString = h + timeString.substr(2,
        3) + ampm;
    },
    getWeekday(date) {
      return dayjs(date).
        weekday();
    },
    selectDate(newSelectedDate) {
      this.selectedDate = newSelectedDate;
    },
    clear() {
      this.startDate = null;
      this.startTime = null;
    }
  }
};
</script>
