var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accordion"},[_c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"d-flex justify-content-between",attrs:{"header-tag":"header"}},[_c('div',{staticClass:"card-title mb-0"},[_c('div',{staticClass:"oneTwoThree"},[_c('span',[_vm._v(_vm._s(_vm.step))])]),_vm._v(" Booking ")]),(_vm.form.submited)?_c('div',{staticClass:"card-title mb-0"},[_vm._v("Confirmed")]):_vm._e()]),_c('b-collapse',{attrs:{"id":'accordion-confirm',"visible":_vm.form.submited}},[_c('b-card-body',[_c('div',{staticClass:"d-flex justify-content-around pt-3 pb-2"},[_c('div',[_c('h6',{staticClass:"font-weight-lighter"},[_vm._v(" "+_vm._s(_vm.form.type)+" ")]),_c('h3',[_vm._v(_vm._s(_vm._f("moment")(_vm.form.startDate,"dddd, MMM Do")))]),_c('h6',[_vm._v(_vm._s(_vm.form.startTime)+" "),(_vm.form.hostName)?_c('span',[_vm._v(" with "+_vm._s(_vm.form.hostName))]):_vm._e()])]),_c('div',{staticClass:"d-flex flex-column"},[_c('b-button',{staticClass:"cal",attrs:{"href":'https://www.google.com/calendar/render?action=TEMPLATE&text=' +
                          _vm.subject +
                          '&details=Free+consultation+to+discuss+and+take+your+instructions.+' +
                          encodeURIComponent(_vm.uri) +
                          '&location=Telephone&dates=' +
                          _vm.calStartDate +
                          '%2F' +
                          _vm.calEndDate,"target":"_blank"}},[_vm._v("Add to Google ")]),_c('b-button',{staticClass:"mt-3 cal",on:{"click":function($event){return _vm.$emit('createIcs')}}},[_vm._v("Add to iCal/Outlook ")])],1)])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }