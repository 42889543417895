import Vue from 'vue';
import Router from 'vue-router';

import BookingForm from "./forms/BookingForm";
import Cancellation from "./forms/Cancellation";

Vue.use(Router);

export const routes = [
  // create new clients
  {
    name:'bookingHostNewClientLegacy',
    path: '/booking/:host/:type/:eventKey?',
    component: BookingForm
  },
  {
    name:'bookingHostNewClient',
    path: '/booking_new/:host/:type/:eventKey?',
    component: BookingForm
  },
  {
    name:'bookingSourceNewClient',
    path: '/booking_new_source/:role/:type/:leadSource/:eventKey?',
    component: BookingForm
  },
  {
    name:'bookingRoleNewClient',
    path: '/booking_new_role/:role/:type',
    component: BookingForm
  },
  // books without clients
  {
    name:'bookingHostNonClient',
    path: '/booking_new_nonclient/:host/:title/:eventKey?',
    component: BookingForm
  },
  // books existing clients
  {
    name: 'bookingHost',
    path: '/booking_with_host/:host/:type/:ids/:names/:eventKey?',
    component: BookingForm
  },
  {
    name: 'bookingRole',
    path: '/booking_with/:role/:type/:ids/:names/:eventKey?',
    component: BookingForm
  },
  {
    name:'cancelBookingbookingHostNewClientLegacy',
    path: '/cancel/:eventKey?',
    component: Cancellation
  },
]

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open',
  routes
});


export default router;
