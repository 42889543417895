<template>
  <b-container>


    <b-overlay
          :show="cancelEventInProgress"
          opacity="0"
          spinner-type=""
        >
      <div style="padding: 30px">
        <b-alert show variant="success" v-if="eventCancelled">
          Your appointment cancelled
        </b-alert>

        <b-alert show variant="danger" v-if="errorMessage">
          Error Occurred: {{ errorMessage }}
        </b-alert>

      </div>


    </b-overlay>

  </b-container>
</template>

<script>

import {http} from "@/services";
import {linkHelpers} from '@/forms/Mixins/linkHelpers';

export default {
  name: 'Cancellation',
  mixins:[linkHelpers],
  watch: {
    $route() {
      this.cancelEvent();
    },
  },
  async created() {
    await this.cancelEvent();
  },
  data() {
    return {
      cancelEventInProgress: false,
      errorMessage: null,
      eventCancelled: false,
      form: {
        eventKey: null,
      },
    };
  },
  methods: {
    async cancelEvent() {
      this.cancelEventInProgress = true;
      if (this.$route.params.eventKey) {
        http
          .get(`scheduledevent/?eventKey=` + this.$route.params.eventKey)
          .then((response) => {

            if (response.data.length) {
              if(["Cancelled by Advisor", "Cancelled by Client"].includes(response.data[0].status)){
                this.errorMessage = "Event already cancelled!";
                this.cancelEventInProgress = false;
                return true
              }
              this.form.eventKey = response.data[0].eventKey;

              // cancel event
              http
              .patch(`cancelevent/?eventKey=` + this.form.eventKey)
              .then(() => {
                this.eventCancelled = true;
                this.cancelEventInProgress = false;
              })
              .catch((e) => {
                this.errors.push(e);
                this.cancelEventInProgress = false;
              });


             } else {
              this.errorMessage = "Event not found!";
            }
            this.cancelEventInProgress = false;
          })
          .catch((e) => {
            this.errorMessage = e.message;
            this.cancelEventInProgress = false;
          });
      }
      return true
    },

  },
};
</script>
