<template>
  <div class="accordion">
    <div>
      <b-card no-body>
        <b-card-header class="d-flex justify-content-between"
                       header-tag="header">
          <div class="card-title mb-0">
            <div class="oneTwoThree">
              <span>{{step}}</span>
            </div>
            Booking
          </div>
          <div class="card-title mb-0"
               v-if="form.submited">Confirmed</div>
        </b-card-header>
        <b-collapse :id="'accordion-confirm'"
                    :visible="form.submited">
          <b-card-body>
            <div class="d-flex justify-content-around pt-3 pb-2">
              <div>
                <h6 class="font-weight-lighter">
                  {{ form.type }} </h6>
                <h3>{{ form.startDate | moment("dddd, MMM Do") }}</h3>
                <h6>{{ form.startTime }} <span v-if="form.hostName"> with {{form.hostName}}</span></h6>
              </div>
              <div class="d-flex flex-column">
                <b-button :href="
                            'https://www.google.com/calendar/render?action=TEMPLATE&text=' +
                            subject +
                            '&details=Free+consultation+to+discuss+and+take+your+instructions.+' +
                            encodeURIComponent(uri) +
                            '&location=Telephone&dates=' +
                            calStartDate +
                            '%2F' +
                            calEndDate
                          "
                          class="cal"
                          target="_blank">Add to Google
                </b-button>
                <b-button class="mt-3 cal"
                          @click="$emit('createIcs')">Add to iCal/Outlook
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>

import moment from "moment";

export default {
  name: "meetingConfirmationPanel",
  props: [
    'value','step'
  ],
  computed: {
    form: {
      set(value) {
        this.$emit('input',
          value);
      },
      get() {
        return this.value;
      }
    },
    subject() {
      if (this.form.type) {
        return this.form.type.split(" ").
          join("+");
      }
      return "";
    },
    uri() {
      return "<a href='" + this.$parent.$parent.plainuri + "'>Reschedule</a>";
    },
    calStartDate() {
      return moment(this.form.startDate + " " + this.form.startTime).
        format("YYYYMMDDTHHmm");
    },
    calEndDate() {
      return moment(this.form.startDate + " " + this.form.endTime).
        format("YYYYMMDDTHHmm");
    },
  }
}

</script>
