export const toast = {
  methods: {
    toast(
      message,
      title = "Success",
      variant = "primary",
      duration = 5000,
      position = "b-toaster-top-right"
    ) {
      if (variant === "danger") {
        duration = 10000;
        position = "b-toaster-top-full";
      }

      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: duration,
        appendToast: true,
        variant: variant,
        toaster: position
      });
    },
    toastError(
      message
    ) {
      this.toast(message, "Error", "danger");
    },
    async message(message, title = "Complete", variant = "success") {
      return this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true
        })
        .then((value) => {
          return value;
        })
        .catch((error) => {
          // An error occurred
          console.log(error);
        });
    }
  }
};

export default toast;
