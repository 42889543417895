import axios from "axios";

// use environment variable if set
let BASEURL =
  process.env.NODE_ENV === "production"
    ? `${process.env.VUE_APP_BACKEND_URL}/api/`
    : "http://127.0.0.1:8000/api/";

export const http = axios.create({
  baseURL: BASEURL,
  xhrFields: {
    withCredentials: false,
  },
  headers: {
    "Content-Type": "application/json"
  },
});

const errorInterceptor = (error) => {
  return Promise.reject(error);
};

const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      break;
    default:
      break;
  }
  return response;
};

http.interceptors.response.use(responseInterceptor, errorInterceptor);
