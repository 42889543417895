<template>
  <span>
    <b-form-group
      v-if="show_primary" v-slot="{ ariaDescribedby }"
      class="radio-type-buttons">
      <b-form-radio
        v-for="(option, index) in optionsAction"
        :key="'optionsMode' + index"
        v-model="display_type"
        :aria-describedby="ariaDescribedby"
        @change="updateFormType"
        :value="option.value">
        <span v-html="option.text"/>
      </b-form-radio>
    </b-form-group>

    <b-form-group
      v-if="show_secondary"
      style="pointer-events:none"
      v-slot="{ ariaDescribedby }"
      class="radio-type-buttons">
      <b-form-radio
        v-for="(option, index) in optionsAction"
        :key="'optionsMode' + index"
        v-model="secondary"
        :aria-describedby="ariaDescribedby"
        :value="option.value">
        <span v-html="option.text"/>
      </b-form-radio>
    </b-form-group>
  </span>

</template>
<script>

export default {
  name: "MeetingType",
  props: {
    value: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      required: false
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },
    show_primary() {
      return this.unlock_on_type.includes(this.form.type);
    },
    show_secondary() {
      return this.lock_on_type.includes(this.form.type);
    },
    secondary() {
      return this.optionsAction.find(x => x.secondaryValues.includes(this.value.type)).value;
    },
  },
  watch: {
    'form.type': {
      immediate: true,
      deep: false,
      handler(newVal, oldVal) {
        // on page load with no old value
        if (this.role) {
          // set the display type based on the role
          this.display_type = this.optionsAction.find(option => option.role === this.role).value
        } else {
          // set the display type based on the form type
          this.display_type = this.optionsAction.find(x => x.value === newVal).value
        }

        if (!oldVal) return // do not allow double fetching on page load
        // set corresponding role
        if (newVal === 'Estate Planning Consultation (& Free Will)' || newVal === 'Estate Planning Consultation Referral Discounted Services') {
          this.$emit('setRole', 'Estate Planner Full');
        } else {
          // added this to show type of appointment
          this.display_type = this.optionsAction.find(x => x.value === newVal).value
          this.$emit('setRole', this.optionsAction.find(x => x.value === newVal).role);
        }
      }
    }
  },
  created() {
    // a little fix for unlocking free will
    if (this.form.type === 'Estate Planning Consultation (& Free Will)') {
      this.display_type = 'Estate Planning Consultation';
      this.event_type_store = 'Estate Planning Consultation (& Free Will)';
    }
    if (this.form.type === 'Estate Planning Consultation Referral Discounted Services') {
      this.display_type = 'Estate Planning Consultation';
      this.event_type_store = 'Estate Planning Consultation Referral Discounted Services';
    }
  },
  methods: {
    updateFormType() {
      this.form.type = this.event_type_store && this.display_type === 'Estate Planning Consultation' ? this.event_type_store : this.display_type;
    },
  },
  data() {
    return {
      event_type_store: null,
      display_type: null,
      unlock_on_type: [
        'Estate Planning Consultation (& Free Will)',
        'Estate Planning Consultation Referral Discounted Services',
        'General Consultation',
        'Estate Planning Consultation',
        'Mortgage Consultation',
        'Insurance Consultation',
        'Insurance Consultation (Business)'
      ],
      lock_on_type: [
        'Estate Planning Checking',
        'Estate Planning Final',
        'Mortgage Sign Up',
        'Re-Mortgage Consultation',
        'Insurance Presentation',
        'Insurance Review (Existing)',
        'Estate Planning Review (Existing)',
        'Estate Planning Review Final (Existing)'
      ],
      optionsAction: [
        {
          value: 'Estate Planning Consultation',
          role: 'Estate Planner Full',
          text: `Will<br>Writing`,
          secondaryValues: [
            'Estate Planning Consultation (& Free Will)',
            'Estate Planning Consultation Referral Discounted Services',
            'Estate Planning Checking', 'Estate Planning Final',
            'Estate Planning Review (Existing)',
            'Estate Planning Review Final (Existing)'
          ]
        },
        {
          value: 'Mortgage Consultation',
          role: 'Mortgage Advisor',
          text: `Mortgage<br>Advice`,
          secondaryValues: [
            'Mortgage Sign Up',
            'Re-Mortgage Consultation'
          ],
        },
        {
          value: 'Insurance Consultation',
          role: 'Insurance Website',
          text: `Insurance<br>Advice`,
          secondaryValues: [
            'Insurance Presentation',
            'Insurance Review (Existing)'
          ]
        },
        {
          value: 'General Consultation',
          role: 'Estate Planner Full',
          text: `Other<br>Advice`,
          secondaryValues: [
          ]
        }
      ],
    };
  },
};
</script>
